export default Object.freeze({
  getClients: {
    GET: () => {
      return 'customers/customers'
    }
  },
  addCustomer: {
    GET: 'customers/customer'
  },
  editClient: {
    GET: (id) => {
      return `customers/${id}`
    }
  },
  getCustomer: {
    GET: (id) => {
      return `customers/${id}`
    }
  },
  getCustomerByEmail: {
    GET: (email) => {
      return `customers/${email}/info`
    }
  },
  deleteCustomer: {
    GET: (id) => {
      return `customers/${id}`
    }
  },
  getClientModules: {
    GET: (id) => {
      return `customers/${id}/modules`
    }
  },
  getCustomerOverview: {
    GET: () => {
      return 'customers/dashboard'
    }
  },
  getCustomerInvoiceReport: {
    GET: (id) => {
      return `customers/${id}/invoicereport`
    }
  },
  getCustomerMonthlyReport: {
    GET: (id) => {
      return `customers/${id}/monthlyreport`
    }
  },
  getCustomerWeeklyReport: {
    GET: (id) => {
      return `customers/${id}/weeklyreport`
    }
  }
})

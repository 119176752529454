<template>
  <div>
    <article
      v-for="(mod, modIndex) in data.modules"
      :key="modIndex"
      class="panel-block is-block"

      href="#"
    >
      <div class="panel-heading">
        <p>{{ mod.name }}, {{ mod.startDate | formatDate }} - {{ mod.endDate | formatDate }}, {{ mod.numberOfHours }} ore</p>
        <b-loading
          :is-full-page="true"
          :active="isLoading"
        />
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              label="Rapoarte"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <b-dropdown-item
            aria-role="listitem"
            @click="navigatetoReports(data.id)"
          >
            Descarca rapoarte factura
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            @click="downloadMonthlyReport(data.id, data.name)"
          >
            Descarca raport lunar
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <a
        v-for="(group, groupIndex) in mod.groups"
        :key="groupIndex"
        class="panel-block is-block"
        href="#"
      >
        <div class="container">
          <nav class="level">
            <!-- Left side -->
            <div class="level-left">
              <p class="level-item has-text-centered">
                <b-field
                  grouped
                  :message="`${group.teacher}, ${group.level}`"
                  expanded
                >
                  <p class="control">
                    {{ group.name }}
                  </p>
                </b-field>
              </p>
            </div>
            <!-- Right side -->
            <div class="level-right">
              <p class="level-item">
                <router-link
                  class="button is-small is-primary"
                  :to="{name:'groupattendance', params: {id: group.id}}"
                >
                  Prezente
                </router-link>

              </p>
            </div>
          </nav></div>
      </a>
    </article>
  </div>
</template>

<script>

import ClientService from '@/services/clients.service'
import * as moment from 'moment/moment'
import 'moment/locale/ro'

export default {
  name: 'ClientOverviewDashboard',
  props: {
    data: {
      type: Object,
      required: true,
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const getRomanianMonthName = () => {
      moment.locale('ro')
      const currentDate = moment()
      const romanianMonthName = currentDate.format('MMMM')
      return romanianMonthName
    }
    return {
      isLoading: false,
      getRomanianMonthName
    }
  },
  methods: {
    async downloadCustomerReport (customerId, customerName) {
      try {
        this.isLoading = true
        const { getCustomerInvoiceReport } = ClientService
        const currentDate = moment()
        await getCustomerInvoiceReport(customerId, customerName, currentDate)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$buefy.snackbar.open({
          message: 'Eroare la generararea raportului.',
          queue: false
        })
      }
    },
    navigatetoReports (clientId) {
      this.$router.push({ name: 'reports.customerId', params: { customerId: clientId } })
    },
    async downloadMonthlyReport (customerId, customerName) {
      try {
        this.isLoading = true
        const { getMonthlyReport } = ClientService
        await getMonthlyReport(customerId, customerName)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$buefy.snackbar.open({
          message: 'Eroare la generararea raportului.',
          queue: false
        })
      }
    }
  }
}
</script>

import clientEndpoints from './endpoints/client-endpoints'
import apiConfig from '../config/api'
import http from './http'

const {
  getClients,
  getCustomer,
  getCustomerByEmail,
  addCustomer,
  editClient,
  deleteCustomer,
  getClientModules,
  getCustomerOverview,
  getCustomerInvoiceReport,
  getCustomerMonthlyReport,
  getCustomerWeeklyReport
} = clientEndpoints
const { baseURL } = apiConfig

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRWVEhnbTVTUTltY25QVEVVQ0p1dyJ9.eyJpc3MiOiJodHRwczovL2Rldi10NTVwcTdieS5ldS5hdXRoMC5jb20vIiwic3ViIjoibHd3V3JaQ05TdDNLeGY5RGIzNjgzZmtUQk9SZ2J5M1JAY2xpZW50cyIsImF1ZCI6InByb2xpbmd1YSIsImlhdCI6MTY1Mjg4MjIxOCwiZXhwIjoxNjUyOTY4NjE4LCJhenAiOiJsd3dXclpDTlN0M0t4ZjlEYjM2ODNma1RCT1JnYnkzUiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.V512m-sZCtXkeetZgUFbWfTxzkXjakU7HvOrSACGOcK7Xpc8GKxK-MagebkqSKrfabHJe9imENDcCtxr1CPAFwrkOVXCNLEob4Gjxxs_pOJSv5Rg4yPXdhyi2QDhrVYjT2J4aRe4Dh_9mRnwzKwPyugn04u5ClIl4dBAkn7m9TyJlGgWyaEzYtup0qn0-KKqVFIF0HPRE4ai6rEjUsiCBlK9Gfzvuwua8eJOL4s8EJEkYUjVXdsCLxRVAoGQRypgc0wO0Nc-qTpVdpeS_wnWS8MfAjrUPFYFjzgZZar8Y6RhpANdi5YiryNwGtW2_Gqp7IKRxfW8R0uZeNdV1k39_g'

const ClientService = {
  async getWeeklyReport (clientId, clientName) {
    const getCustomerWeeklyReportUrl = `${baseURL}${getCustomerWeeklyReport.GET(clientId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getCustomerWeeklyReportUrl,
        { responseType: 'blob' },
        {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"',
            Authorization: `token ${token}`
          }
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `Raport_detaliat_prezente_ ${clientName}.xlsx`
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
  },
  async getMonthlyReport (clientId, clientName) {
    const getGroupMonthlyReportUrl = `${baseURL}${getCustomerMonthlyReport.GET(clientId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupMonthlyReportUrl,
        { responseType: 'blob' },
        {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"',
            Authorization: `token ${token}`
          }
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `Raport_prezente_lunare_ ${clientName}.xlsx`
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
  },

  async getCustomerInvoiceReport (customerId, clientName, reportDate) {
    const getCustomerInvoiceReportUrl = `${baseURL}${getCustomerInvoiceReport.GET(customerId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getCustomerInvoiceReportUrl, { id: customerId, date: reportDate },
        { responseType: 'blob' },
        {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"',
            Authorization: `token ${token}`
          }
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `Raport_factura_ ${clientName}.xlsx`
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
  },
  async getClients () {
    const getClientsURL = `${baseURL}${getClients.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getClientsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async addCustomer (customer) {
    const getAddClientUrl = `${baseURL}${addCustomer.GET}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getAddClientUrl,
        {
          name: customer.name,
          email: customer.email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editClient (client) {
    const getEditClientUrl = `${baseURL}${editClient.GET(client.id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditClientUrl,
        {
          id: client.id,
          name: client.name,
          email: client.email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getCustomerByEmail (email) {
    const getClientByEmailURL = `${baseURL}${getCustomerByEmail.GET(email)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getClientByEmailURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getCustomer (id) {
    const getClientURL = `${baseURL}${getCustomer.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getClientURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async deleteCustomer (id) {
    const getDeleteCustomerUrl = `${baseURL}${deleteCustomer.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .delete(
        getDeleteCustomerUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getClientModules (id) {
    const getClientModulesUrl = `${baseURL}${getClientModules.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getClientModulesUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getCustomersOverview (id) {
    const getCustomerOverviewURL = `${baseURL}${getCustomerOverview.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getCustomerOverviewURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  }
}

export default ClientService

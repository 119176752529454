export default Object.freeze({
  getClientOverview: {
    GET: (id) => {
      return `dashboard/clients/${id}/overview`
    }
  },
  getTeacherOverview: {
    GET: (id) => {
      return `dashboard/teachers/${id}/overview`
    }
  },
  getGeneralOverview: {
    GET: () => {
      return 'dashboard/general/overview'
    }
  }
})

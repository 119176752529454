import { extend } from 'vee-validate'
import { messages } from 'vee-validate/dist/locale/ro.json'
import * as rules from 'vee-validate/dist/rules'

const veeValidateConfigure = () => {
  Object.keys(rules).forEach((rule) => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule]
    })
  })
}

export default veeValidateConfigure

<template>
  <div v-if="$auth.isAuthenticated">
    <card-component
      title="Centralizator cursuri Linguapro"
      icon="table"
    >
      <b-message
        v-if="errorMessage"
        type="is-warning"
        has-icon
      >
        {{ errorMessage }}
      </b-message>

      <div v-if="teacherOverview && teacherOverview !== undefined">
        <b-loading
          :is-full-page="true"
          :active="teacherOverviewLoading && userRole === 'Teacher'"
        />
        <teacher-overview-dashboard :data="teacherOverview" />
      </div>

      <div v-if="clientOverview && clientOverview !== undefined">
        <b-loading
          :is-full-page="true"
          :active="clientOverviewLoading && userRole === 'Client'"
        />
        <client-overview-dashboard :data="clientOverview" />
      </div>

      <div v-if="generalOverview && generalOverview !== undefined">
        <b-loading
          :is-full-page="true"
          :active="generalOverviewLoading && userRole === 'Coordinator'"
        />
        <general-overview-dashboard :data="generalOverview" />
      </div>
    </card-component>
  </div>
  <div v-else>
    <section class="hero is-white is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns  is-vcentered reverse-columns">
            <div
              class="column
          is-10-mobile
          is-10-tablet
          is-5-desktop
          is-5-widescreen
          is-5-fullhd "
              data-aos="fade-down"
            >
              <h1 class="title titled is-1 mb-6">
                <vue-typer text="Centralizator" />
              </h1>
              <div class="buttons">
                <h2 class="subtitled subtitle">
                  Accesează instant date esențiale cu un centralizator bine conceput.
                </h2>
              </div>
            </div>
            <div
              data-aos="fade-right"
              class="column
          is-10-mobile
          is-10-tablet
          is-4-desktop
          is-7-widescreen
          is-4-fullhd is-offset-1-fullhd"
            >
              <figure class="image is-square">
                <!-- <img src="../images/undraw_Camera_re_cnp4.svg"> -->
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero is-white is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns  is-vcentered">
            <div
              data-aos="fade-left"
              class="column
          is-10-mobile is-offset-1-mobile
          is-10-tablet is-offset-1-tablet
          is-4-desktop is-offset-1-desktop
          is-4-widescreen is-offset-1-widescreen
          is-4-fullhd is-offset-1-fullhd"
            >
              <figure class="image is-square">
                <!-- <img src="../images/undraw_Camera_re_cnp4.svg"> -->
              </figure>
            </div>
            <div
              data-aos="fade-down"
              class="column
          is-10-mobile is-offset-1-mobile
          is-10-tablet is-offset-1-tablet
          is-5-desktop is-offset-1-desktop
          is-5-widescreen is-offset-1-widescreen
          is-5-fullhd is-offset-1-fullhd"
            >
              <h1 class="titled title is-1 mb-6">
                <vue-typer text="Monitorizare" />
              </h1>
              <h2 class="subtitled subtitle">
                Monitorizează progresul studenților și lecțiilor parcurse.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero is-white is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns  is-vcentered reverse-columns">
            <div
              data-aos="fade-right"
              class="column
          is-10-mobile is-offset-1-mobile
          is-10-tablet is-offset-1-tablet
          is-5-desktop is-offset-1-desktop
          is-5-widescreen is-offset-1-widescreen
          is-5-fullhd is-offset-1-fullhd"
            >
              <h1 class="title titled is-1 mb-6">
                Administrare
              </h1>
              <h2 class="subtitle subtitled">
                Administrează informații despre cursuri, studenți, lecții și facturi într-un singur loc.
              </h2>
            </div>
            <div
              data-aos="fade-down"
              class="column
          is-10-mobile is-offset-1-mobile
          is-10-tablet is-offset-1-tablet
          is-4-desktop is-offset-1-desktop
          is-4-widescreen is-offset-1-widescreen
          is-4-fullhd is-offset-1-fullhd"
            >
              <figure class="image is-square">
                <!-- <img src="../images/undraw_Camera_re_cnp4.svg"> -->
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from '@vue/composition-api'
import { useStore } from '@/store'
import * as chartConfig from '@/components/Charts/chart.config.js'
import CardComponent from '@/components/CardComponent.vue'
import GeneralOverviewDashboard from '@/components/home/overview-dashboard.vue'
import TeacherOverviewDashboard from '@/components/home/teacher-dashboard.vue'
import ClientOverviewDashboard from '@/components/home/client-dashboard.vue'
import DashboardService from '@/services/dashboard.service'
import { VueTyper } from 'vue-typer'

export default {
  name: 'Home',
  components: {
    CardComponent,
    GeneralOverviewDashboard,
    TeacherOverviewDashboard,
    ClientOverviewDashboard,
    VueTyper
  },
  setup (props, { root: { $buefy } }) {
    const store = useStore()

    const isDarkModeActive = computed(() => store.state.isDarkModeActive)

    const chartOptions = chartConfig.chartOptionsMain

    const chartData = ref(null)
    const generalOverview = ref({})
    const clientOverview = ref({})
    const teacherOverview = ref([])
    const teacherOverviewLoading = ref([])
    // Add a loading state for the general overview
    const generalOverviewLoaded = ref(false)
    const generalOverviewLoading = ref(false)
    const titleStack = computed(() => ['Centralizator cursuri Linguapro'])

    const clientOverviewLoaded = ref(false)
    const clientOverviewLoading = ref(false)

    const teacherOverviewLoaded = ref(false)
    const errorMessage = ref('')
    const userRole = computed(() => store.state.userRole)

    watchEffect(async () => {
      if (userRole.value === 'Coordinator') {
        await getGeneralOverview()
      } else if (userRole.value === 'Client') {
        const clientId = store.state.clientId
        await getClientOverview(clientId)
      } else if (userRole.value === 'Teacher') {
        const teacherId = store.state.teacherId
        await getTeacherOverview(teacherId)
      }
    }
    )

    async function getGeneralOverview () {
      generalOverviewLoading.value = true
      const { getGeneralOverview } = DashboardService
      const response = await getGeneralOverview()

      const { data } = response
      generalOverview.value = data
      generalOverviewLoaded.value = true
      generalOverviewLoading.value = false
      return data
    }

    async function getClientOverview (clientId) {
      clientOverviewLoading.value = true
      const { getClientOverview } = DashboardService
      const response = await getClientOverview(clientId)
      const { data } = response
      clientOverview.value = data
      clientOverviewLoaded.value = true
      clientOverviewLoading.value = false
      if (clientOverview.value === undefined || clientOverview.value.modules.length === 0) {
        errorMessage.value = 'Nu exista inregistrari pentru acest utilizator'
      } else {
        errorMessage.value = ''
      }
      return data
    }

    async function getTeacherOverview (teacherId) {
      teacherOverviewLoading.value = true
      const { getTeacherOverview } = DashboardService
      const response = await getTeacherOverview(teacherId)
      const { data } = response
      teacherOverview.value = data
      teacherOverviewLoaded.value = true
      teacherOverviewLoading.value = false
      if (teacherOverview.value === undefined || teacherOverview.value.length === 0) {
        errorMessage.value = 'Nu exista inregistrari pentru acest utilizator'
      } else {
        errorMessage.value = ''
      }
      return data
    }

    return {
      titleStack,
      isDarkModeActive,
      chartOptions,
      chartData,
      clientOverview,
      teacherOverview,
      generalOverview,
      generalOverviewLoaded,
      generalOverviewLoading,
      clientOverviewLoaded,
      clientOverviewLoading,
      teacherOverviewLoaded,
      teacherOverviewLoading,
      errorMessage,
      userRole
    }
  }
}
</script>

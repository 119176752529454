<template>
  <div>
    <div v-if="data.length > 0">
      <div
        v-for="(client, clientIndex) in data"
        :key="clientIndex"
        class="box"
      >
        <b-field>
          <p class="control">
            <b-button
              :label="`${client.name}`"
              type="is-danger"
            />
          </p>
        </b-field>
        <article
          v-for="(mod, modIndex) in client.modules"
          :key="modIndex"
          class="panel-block is-block"
          href="#"
        >
          <div class="panel-heading">
            <p>{{ mod.name }}, {{ mod.startDate | formatDate }} - {{ mod.endDate | formatDate }}, {{ mod.numberOfHours }} ore</p>
          </div>
          <a
            v-for="(group, groupIndex) in mod.groups"
            :key="groupIndex"
            class="panel-block is-block"
            href="#"
          >
            <div class="container">
              <nav class="level">
                <!-- Left side -->
                <div class="level-left">
                  <p class="level-item">
                    <b-field
                      grouped
                      :message="`${group.level}, ${group.type}, ${group.teacher}`"
                      expanded
                    >
                      <label>
                        {{ group.name }}
                      </label>
                    </b-field>
                  </p>
                </div>
                <!-- Right side -->
                <div class="level-right">
                  <p class="level-item">
                    <router-link
                      class="button is-small is-primary"
                      :to="{name:'groupattendance', params: {id: group.id}}"
                      @click.native="remove"
                    >
                      Prezente
                    </router-link>

                  </p>
                </div>
              </nav></div>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeacherOverviewDashboard',
  props: {
    data: {
      type: Array,
      required: true,
      default: null
    }
  }
}
</script>

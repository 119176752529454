<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <article
      v-for="(client, clientIndex) in data.customers"
      :key="clientIndex"
      class="box"
    >
      <b-field :message="`Numar de module definite: ${client.modules.length}`">
        <p class="control">
          <b-dropdown aria-role="list">
            <template #trigger="{ active }">
              <b-button
                :label="`${client.name}`"
                type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadCustomerReport(client.id, client.name)"
            >
              {{ `Descarca raport factura (${getRomanianMonthName()}) ` }}
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="navigatetoReports()"
            >
              Arhiva rapoarte factura...
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadMonthlyReport(client.id, client.name)"
            >
              Descarca raport lunar
            </b-dropdown-item>
          </b-dropdown>
        </p>
      </b-field>
      <article
        v-for="(mod, modIndex) in client.modules"
        :key="modIndex"
        class="panel-block is-block"
        href="#"
      >
        <div class="panel-heading">
          <p>{{ mod.name }}, {{ mod.startDate | formatDate }} - {{ mod.endDate | formatDate }}, {{ mod.numberOfHours }} ore</p>
        </div>
        <a
          v-for="(group, groupIndex) in mod.groups"
          :key="groupIndex"
          class="panel-block is-block"
          href="#"
        >
          <div class="container">
            <nav class="level">
              <!-- Left side -->
              <div class="level-left">
                <p class="level-item">
                  <b-field
                    grouped
                    :message="`${group.level}, ${group.type}, ${group.teacher}`"
                    expanded
                  >
                    <p class="control">
                      {{ group.name }}
                    </p>
                  </b-field>
                </p>
              </div>
              <!-- Right side -->
              <div class="level-right">
                <p class="level-item">
                  <router-link
                    class="button is-small is-primary"
                    :to="{name:'groupattendance', params: {id: group.id}}"
                  >
                    Prezente
                  </router-link>

                </p>
              </div>
            </nav></div>
        </a>
      </article>
    </article>
  </div>
</template>

<script>

import ClientService from '@/services/clients.service'
import * as moment from 'moment/moment'
import 'moment/locale/ro'

export default {
  name: 'GeneralOverviewDashboard',
  props: {
    data: {
      type: Object,
      required: true,
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const getRomanianMonthName = () => {
      moment.locale('ro')
      const currentDate = moment()
      const romanianMonthName = currentDate.format('MMMM')
      return romanianMonthName
    }
    return {
      isLoading: false,
      getRomanianMonthName
    }
  },
  methods: {
    async downloadCustomerReport (customerId, customerName) {
      try {
        this.isLoading = true
        const { getCustomerInvoiceReport } = ClientService
        const currentDate = moment()
        await getCustomerInvoiceReport(customerId, customerName, currentDate)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$buefy.snackbar.open({
          message: 'Eroare la generararea raportului.',
          queue: false
        })
      }
    },
    navigatetoReports () {
      this.$router.push({ path: '/reports' })
    },
    async downloadMonthlyReport (customerId, customerName) {
      try {
        this.isLoading = true
        const { getMonthlyReport } = ClientService
        await getMonthlyReport(customerId, customerName)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$buefy.snackbar.open({
          message: 'Eroare la generararea raportului.',
          queue: false
        })
      }
    }
  }
}
</script>
